function getLang() {
  var lang = getFirstBrowserLanguage() || 'es';
  if (lang.length > 2) lang = lang.substring(0, 2);
  var src = 'assets/lang/'+ lang +'.json';

  $.getJSON(src, langReady)
    .fail(function() {
      loadScript(src, langReady);
    });
}


function getFirstBrowserLanguage() {
  var nav = window.navigator,
  browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'],
  i,
  language;

  // support for HTML 5.1 "navigator.languages"
  if (Array.isArray(nav.languages)) {
    for (i = 0; i < nav.languages.length; i++) {
      language = nav.languages[i];
      if (language && language.length) {
        return language;
      }
    }
  }

  // support for other well known properties in browsers
  for (i = 0; i < browserLanguagePropertyKeys.length; i++) {
    language = nav[browserLanguagePropertyKeys[i]];
    if (language && language.length) {
      return language;
    }
  }

  return null;
}


function loadScript(src, callback) {
  var head = document.getElementsByTagName('head')[0];
  var script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = src;
  script.async = false;
  script.onload = callback;
  head.appendChild(script);
}


function langReady(obj) {
  console.log('language loaded');
  $(document).trigger('langReady');
}
